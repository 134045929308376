
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="bottomTaskType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">客户列表</div>
          <div v-if="bottomTaskType!==1" :class="bottomTaskType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">客户详情</div>
        </div>

        <component :is="currentComponent + 'Component'" ref="bottom" :isManage="true"   @on-detail="showDetail" />
    </div>
</template>

<script>
export default {
  components: {
    listComponent: (resolve) => {
      require(['@/components/advertiser/CustomerList'], resolve)
    },
    detailComponent: (resolve) => {
      require(['@/components/advertiser/CustomerDetail'], resolve)
    }
  },
  data () {
    return {
      bottomTaskType: 1,
      currentComponent: 'list'
    }
  },
  created () {
  },
  methods: {
    handleChangeTag (val) {
      this.bottomTaskType = val
      if (val === 1) {
        this.currentComponent = 'list'
      } else if (val === 2) {
        this.currentComponent = 'detail'
      }
    },
    showDetail (id) {
      this.$store.commit('set_advertiser_detailId', id)
      this.handleChangeTag(2)
    }
    // createCustomer () {
    //   this.$emit('on-showLeft')
    // }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.advertiser.beginUpdate
    },
    detailId () {
      return this.$store.state.advertiser.detailId
    }
  },
  watch: {
    beginUpdate () {
      this.$refs.bottom.reloadData()
    }
  }
}
</script>
